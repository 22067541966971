// extracted by mini-css-extract-plugin
export var add_button_style = "compra-module--add_button_style--J1N4P";
export var black = "compra-module--black--Z+2OM";
export var blue = "compra-module--blue--9qm-x";
export var button = "compra-module--button--1dEBb";
export var buttons = "compra-module--buttons--10h0c";
export var buttons_add = "compra-module--buttons_add--AIc7P";
export var buttons_container = "compra-module--buttons_container--By1y1";
export var container = "compra-module--container--AaMGm";
export var flex_title = "compra-module--flex_title--Mc9Tk";
export var info = "compra-module--info--rJ+LR";
export var size = "compra-module--size--Ziurh";
export var yellow = "compra-module--yellow--rfiAT";